import { BrandDot } from '@/components/BrandDot'
import { SectionWrapper } from '@/components/SectionWrapper'
import { StackedCards } from '@/components/StackedCards'
import { useTheme } from '@/store'
import { PortfolioProjectsQuery } from '@/types/__generated__/PortfolioProjectsQuery'
import { fluidFontSize, scrimGradient } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

type Props = {
  id: string
}

const query = graphql`
  query PortfolioProjectsQuery {
    contentfulPagePortfolioProjects {
      projects {
        name
        slug
        category {
          title
        }
        coverImage {
          fluid(
            maxWidth: 1024
            maxHeight: 576
            quality: 85
            resizingBehavior: FILL
          ) {
            ...ContentfulFluid
          }
        }
        gallery {
          __typename
        }
      }
    }
  }
`

export const PortfolioProjects: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const data = useStaticQuery<PortfolioProjectsQuery>(query)

  return (
    <SectionWrapper id={id}>
      <div>
        <h2
          css={css`
            margin-left: -0.05em;
            color: ${theme.color.text_default};
            ${fluidFontSize(30, 48, 320, theme.vwImageCapWidth)};
            letter-spacing: -0.03em;
          `}
        >
          Projects
          <BrandDot />
        </h2>
        <p
          css={css`
            margin-top: 0.5rem;
            margin-bottom: 2rem;
            color: ${theme.color.text_lighter};
            font-style: italic;
            line-height: 1.25;
          `}
        >
          Galleries of our selected projects.
        </p>
      </div>
      {data.contentfulPagePortfolioProjects.projects.map(
        ({ name, category, slug, coverImage, gallery }) => {
          return (
            <article
              key={slug}
              css={css`
                &:not(:first-of-type) {
                  margin-top: 6.25%;
                }
              `}
            >
              <Link to={`/portfolio/projects/${slug}/`}>
                <StackedCards>
                  <>
                    <Img fluid={coverImage.fluid} alt="" />
                    <div
                      css={css`
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        padding: 3.125%;
                        padding-top: 12.5%;

                        ${scrimGradient({
                          color: theme.color.grays[8],
                          direction: 'to top',
                          startAlpha: 0.64,
                        })}
                      `}
                    >
                      <h2
                        css={css`
                          margin-bottom: 0.5em;

                          ${fluidFontSize(16, 30, 320, theme.vwImageCapWidth)};
                          color: ${theme.color.grays[0]};
                          text-shadow: ${theme.textShadow};
                        `}
                      >
                        {name}
                      </h2>
                      <p
                        css={css`
                          ${fluidFontSize(14, 16, 320, theme.vwImageCapWidth)};
                          color: ${theme.color.grays[2]};
                          text-shadow: ${theme.textShadow};
                        `}
                      >
                        <span>{gallery.length}</span>{' '}
                        <span
                          css={css`
                            font-style: italic;
                            margin-right: 0.5rem;
                          `}
                        >
                          images
                        </span>
                        <span>·</span>
                        <span
                          css={css`
                            margin-left: 0.5rem;
                          `}
                        >
                          {category.title}
                        </span>
                      </p>
                    </div>
                  </>
                </StackedCards>
              </Link>
            </article>
          )
        },
      )}
    </SectionWrapper>
  )
}
