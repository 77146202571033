import { Breadcrumb, BreadcrumbItem } from '@/components/Breadcrumb'
import { SEO } from '@/components/SEO'
import { PortfolioLookbooks } from '@/sections/PortfolioLookbooks'
import { PortfolioProjects } from '@/sections/PortfolioProjects'
import { PortfolioPageQuery } from '@/types/__generated__/PortfolioPageQuery'
import { graphql } from 'gatsby'
import React from 'react'

export const query = graphql`
  query PortfolioPageQuery {
    contentfulPagePortfolio {
      title
      metaDescription
    }
  }
`

type Props = {
  data: PortfolioPageQuery
}

const PortfolioPage: React.FC<Props> = ({ data }) => {
  const { title, metaDescription } = data.contentfulPagePortfolio

  return (
    <>
      <SEO title={title} metaDescription={metaDescription} />

      <Breadcrumb>
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem>{title}</BreadcrumbItem>
      </Breadcrumb>

      <PortfolioLookbooks id="portfolio-lookbooks" />

      <PortfolioProjects id="portfolio-projects" />
    </>
  )
}

export default PortfolioPage
